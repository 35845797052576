import React from 'react';
import PropTypes from 'prop-types';

import Title from 'blocks/Title';
import Image from 'blocks/Image';
import Content from 'blocks/Content';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Cta from 'components/Cta';
import { graphql } from 'gatsby';

const Subtitle = ({ location, time }) => (
  <>
    <Icon name="location" /> {location} - <Icon name="clock" /> {time} uur
  </>
);

Subtitle.propTypes = {
  location: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

const Vacature = ({ data }) => {
  const { item } = data;

  return (
    <Layout>
      <Seo
        image={item.titleImage}
        title={item.seoTitle}
        description={item.seoDescription}
      />

      <Title
        image={item.titleImage}
        alt={item.titleHeading}
        subtitle={<Subtitle location={item.location} time={item.hours} />}
        title={item.titleHeading}
      >
        {item.titleText}
      </Title>

      <Section>
        <Content>
          <Heading size="xl" as="h2">
            Wie is Batao?
          </Heading>

          <Text>
            Batao maakt offerte software en is gevestigd in Nijmegen. Ons team
            is gespecialiseerd in het bouwen en onderhouden van software waarmee
            webshops naast het bekende winkelwagentje ook een offerte
            functionaliteit krijgen. Onze software is in gebruik door ca. 15000
            klanten wereldwijd. Bij Batao heerst een positieve en informele
            werksfeer waar hard wordt gewerkt en veel wordt gelachen.
          </Text>
        </Content>
      </Section>

      <Section>
        <Image image={item.activitiesImage} alt="Jouw werkzaamheden" flipMobile>
          <Heading size="xl" as="h2">
            Jouw werkzaamheden
          </Heading>

          <Text>
            <span dangerouslySetInnerHTML={{ __html: item.activitiesText }} />
          </Text>
        </Image>
      </Section>

      <Section>
        <Image
          image={item.knowledgeOfferImage}
          alt="Jij beschikt over deze kennis"
          position="right"
          flipMobile
        >
          <Heading size="xl" as="h2">
            Jij beschikt over deze kennis
          </Heading>

          <Text spacing="lg">
            <span dangerouslySetInnerHTML={{ __html: item.knowledgeText }} />
          </Text>

          <Heading size="xl" as="h2">
            Wat bieden wij jou?
          </Heading>

          <Text>
            <span dangerouslySetInnerHTML={{ __html: item.offerText }} />
          </Text>
        </Image>
      </Section>

      <Section>
        <Content wide>
          <Cta>
            <Content>
              <Heading size="xl" spacing="xs">
                Word jij onze nieuwe collega?
              </Heading>

              <Text color="gray-dark" spacing="lg">
                Mooi! Dan komen we graag snel met je in contact. Je kan jouw CV
                (met eventueel een leuke brief) sturen aan Remco de Jong via
                clan@batao.io.
              </Text>

              <Button color="tertiary" to="/contact">
                Vragen over deze functie?
              </Button>
            </Content>
          </Cta>
        </Content>
      </Section>
    </Layout>
  );
};

Vacature.propTypes = {
  data: PropTypes.shape({
    item: PropTypes.shape({
      seoTitle: PropTypes.string.isRequired,
      seoDescription: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      hours: PropTypes.string.isRequired,
      titleHeading: PropTypes.string.isRequired,
      titleText: PropTypes.string.isRequired,
      titleImage: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
      activitiesText: PropTypes.string.isRequired,
      activitiesImage: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
      knowledgeText: PropTypes.string.isRequired,
      offerText: PropTypes.string.isRequired,
      knowledgeOfferImage: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query vacancyItem($slug: String!) {
    item: vacancies(slug: { eq: $slug }) {
      seoTitle
      seoDescription
      location
      hours
      titleHeading
      titleText
      titleImage {
        sharpImage: childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        publicURL
      }
      activitiesText
      activitiesImage {
        sharpImage: childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        publicURL
      }
      knowledgeText
      offerText
      knowledgeOfferImage {
        sharpImage: childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        publicURL
      }
    }
  }
`;

export default Vacature;
