import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Img from 'components/Image';

import classes from './Image.scss';

function Image({
  image,
  alt,
  position,
  flipMobile,
  fuckUp,
  noShadow,
  children,
}) {
  const className = classNames({
    [classes.root]: true,
    [classes[position]]: true,
    [classes['flip-mobile']]: flipMobile,
    [classes['fuck-up']]: fuckUp,
    [classes['image-only']]: !children,
    [classes['no-shadow']]: noShadow,
  });

  return (
    <div className={className}>
      <div className={classes.wrapper}>
        <Img className={classes.image} image={image} alt={alt} />
      </div>

      {children && <div className={classes.content}>{children}</div>}
    </div>
  );
}

Image.propTypes = {
  image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  alt: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['left', 'right']),
  flipMobile: PropTypes.bool,
  fuckUp: PropTypes.bool,
  noShadow: PropTypes.bool,
  children: PropTypes.node,
};

Image.defaultProps = {
  position: 'left',
  flipMobile: false,
  fuckUp: false,
  noShadow: false,
  children: null,
};

export default Image;
